module.exports.holidays = [
  {
    name: "New Year's Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-01-2021',
    date_year: '2021',
    date_month: '01',
    date_day: '01',
    week_day: 'Friday',
  },
  {
    name: 'Epiphany',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-06-2021',
    date_year: '2021',
    date_month: '01',
    date_day: '06',
    week_day: 'Wednesday',
  },
  {
    name: "Valentine's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '02-14-2021',
    date_year: '2021',
    date_month: '02',
    date_day: '14',
    week_day: 'Sunday',
  },
  {
    name: 'March Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '03-20-2021',
    date_year: '2021',
    date_month: '03',
    date_day: '20',
    week_day: 'Saturday',
  },
  {
    name: 'Good Friday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-02-2021',
    date_year: '2021',
    date_month: '04',
    date_day: '02',
    week_day: 'Friday',
  },
  {
    name: 'Holy Saturday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-03-2021',
    date_year: '2021',
    date_month: '04',
    date_day: '03',
    week_day: 'Saturday',
  },
  {
    name: 'Easter Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-04-2021',
    date_year: '2021',
    date_month: '04',
    date_day: '04',
    week_day: 'Sunday',
  },
  {
    name: 'Easter Monday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-05-2021',
    date_year: '2021',
    date_month: '04',
    date_day: '05',
    week_day: 'Monday',
  },
  {
    name: 'Labor Day - May Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-01-2021',
    date_year: '2021',
    date_month: '05',
    date_day: '01',
    week_day: 'Saturday',
  },
  {
    name: 'Day of the Flag',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-02-2021',
    date_year: '2021',
    date_month: '05',
    date_day: '02',
    week_day: 'Sunday',
  },
  {
    name: 'Constitution Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-03-2021',
    date_year: '2021',
    date_month: '05',
    date_day: '03',
    week_day: 'Monday',
  },
  {
    name: 'Whit Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-23-2021',
    date_year: '2021',
    date_month: '05',
    date_day: '23',
    week_day: 'Sunday',
  },
  {
    name: "Mother's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-26-2021',
    date_year: '2021',
    date_month: '05',
    date_day: '26',
    week_day: 'Wednesday',
  },
  {
    name: 'Corpus Christi',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-03-2021',
    date_year: '2021',
    date_month: '06',
    date_day: '03',
    week_day: 'Thursday',
  },
  {
    name: 'June Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '06-21-2021',
    date_year: '2021',
    date_month: '06',
    date_day: '21',
    week_day: 'Monday',
  },
  {
    name: "Father's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '06-23-2021',
    date_year: '2021',
    date_month: '06',
    date_day: '23',
    week_day: 'Wednesday',
  },
  {
    name: 'Assumption of Mary',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '08-15-2021',
    date_year: '2021',
    date_month: '08',
    date_day: '15',
    week_day: 'Sunday',
  },
  {
    name: 'September Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '09-22-2021',
    date_year: '2021',
    date_month: '09',
    date_day: '22',
    week_day: 'Wednesday',
  },
  {
    name: "All Saints' Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-01-2021',
    date_year: '2021',
    date_month: '11',
    date_day: '01',
    week_day: 'Monday',
  },
  {
    name: 'Independence Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-11-2021',
    date_year: '2021',
    date_month: '11',
    date_day: '11',
    week_day: 'Thursday',
  },
  {
    name: 'December Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '12-21-2021',
    date_year: '2021',
    date_month: '12',
    date_day: '21',
    week_day: 'Tuesday',
  },
  {
    name: 'Christmas Eve',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-24-2021',
    date_year: '2021',
    date_month: '12',
    date_day: '24',
    week_day: 'Friday',
  },
  {
    name: 'Christmas Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-25-2021',
    date_year: '2021',
    date_month: '12',
    date_day: '25',
    week_day: 'Saturday',
  },
  {
    name: 'Second Day of Christmas',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-26-2021',
    date_year: '2021',
    date_month: '12',
    date_day: '26',
    week_day: 'Sunday',
  },
  {
    name: "New Year's Eve",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-31-2021',
    date_year: '2021',
    date_month: '12',
    date_day: '31',
    week_day: 'Friday',
  },
  {
    name: "New Year's Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-01-2022',
    date_year: '2022',
    date_month: '01',
    date_day: '01',
    week_day: 'Saturday',
  },
  {
    name: 'Epiphany',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-06-2022',
    date_year: '2022',
    date_month: '01',
    date_day: '06',
    week_day: 'Thursday',
  },
  {
    name: "Valentine's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '02-14-2022',
    date_year: '2022',
    date_month: '02',
    date_day: '14',
    week_day: 'Monday',
  },
  {
    name: 'March Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '03-20-2022',
    date_year: '2022',
    date_month: '03',
    date_day: '20',
    week_day: 'Sunday',
  },
  {
    name: 'Good Friday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-15-2022',
    date_year: '2022',
    date_month: '04',
    date_day: '15',
    week_day: 'Friday',
  },
  {
    name: 'Holy Saturday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-16-2022',
    date_year: '2022',
    date_month: '04',
    date_day: '16',
    week_day: 'Saturday',
  },
  {
    name: 'Easter Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-17-2022',
    date_year: '2022',
    date_month: '04',
    date_day: '17',
    week_day: 'Sunday',
  },
  {
    name: 'Easter Monday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-18-2022',
    date_year: '2022',
    date_month: '04',
    date_day: '18',
    week_day: 'Monday',
  },
  {
    name: 'Labor Day - May Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-01-2022',
    date_year: '2022',
    date_month: '05',
    date_day: '01',
    week_day: 'Sunday',
  },
  {
    name: 'Day of the Flag',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-02-2022',
    date_year: '2022',
    date_month: '05',
    date_day: '02',
    week_day: 'Monday',
  },
  {
    name: 'Constitution Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-03-2022',
    date_year: '2022',
    date_month: '05',
    date_day: '03',
    week_day: 'Tuesday',
  },
  {
    name: "Mother's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-26-2022',
    date_year: '2022',
    date_month: '05',
    date_day: '26',
    week_day: 'Thursday',
  },
  {
    name: 'Whit Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-05-2022',
    date_year: '2022',
    date_month: '06',
    date_day: '05',
    week_day: 'Sunday',
  },
  {
    name: 'Corpus Christi',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-16-2022',
    date_year: '2022',
    date_month: '06',
    date_day: '16',
    week_day: 'Thursday',
  },
  {
    name: 'June Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '06-21-2022',
    date_year: '2022',
    date_month: '06',
    date_day: '21',
    week_day: 'Tuesday',
  },
  {
    name: "Father's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '06-23-2022',
    date_year: '2022',
    date_month: '06',
    date_day: '23',
    week_day: 'Thursday',
  },
  {
    name: 'Assumption of Mary',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '08-15-2022',
    date_year: '2022',
    date_month: '08',
    date_day: '15',
    week_day: 'Monday',
  },
  {
    name: 'September Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '09-23-2022',
    date_year: '2022',
    date_month: '09',
    date_day: '23',
    week_day: 'Friday',
  },
  {
    name: "All Saints' Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-01-2022',
    date_year: '2022',
    date_month: '11',
    date_day: '01',
    week_day: 'Tuesday',
  },
  {
    name: 'Independence Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-11-2022',
    date_year: '2022',
    date_month: '11',
    date_day: '11',
    week_day: 'Friday',
  },
  {
    name: 'December Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '12-21-2022',
    date_year: '2022',
    date_month: '12',
    date_day: '21',
    week_day: 'Wednesday',
  },
  {
    name: 'Christmas Eve',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-24-2022',
    date_year: '2022',
    date_month: '12',
    date_day: '24',
    week_day: 'Saturday',
  },
  {
    name: 'Christmas Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-25-2022',
    date_year: '2022',
    date_month: '12',
    date_day: '25',
    week_day: 'Sunday',
  },
  {
    name: 'Second Day of Christmas',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-26-2022',
    date_year: '2022',
    date_month: '12',
    date_day: '26',
    week_day: 'Monday',
  },
  {
    name: "New Year's Eve",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-31-2022',
    date_year: '2022',
    date_month: '12',
    date_day: '31',
    week_day: 'Saturday',
  },
  {
    name: "New Year's Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-01-2023',
    date_year: '2023',
    date_month: '01',
    date_day: '01',
    week_day: 'Sunday',
  },
  {
    name: 'Epiphany',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-06-2023',
    date_year: '2023',
    date_month: '01',
    date_day: '06',
    week_day: 'Friday',
  },
  {
    name: "Valentine's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '02-14-2023',
    date_year: '2023',
    date_month: '02',
    date_day: '14',
    week_day: 'Tuesday',
  },
  {
    name: 'March Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '03-20-2023',
    date_year: '2023',
    date_month: '03',
    date_day: '20',
    week_day: 'Monday',
  },
  {
    name: 'Good Friday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-07-2023',
    date_year: '2023',
    date_month: '04',
    date_day: '07',
    week_day: 'Friday',
  },
  {
    name: 'Holy Saturday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-08-2023',
    date_year: '2023',
    date_month: '04',
    date_day: '08',
    week_day: 'Saturday',
  },
  {
    name: 'Easter Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-09-2023',
    date_year: '2023',
    date_month: '04',
    date_day: '09',
    week_day: 'Sunday',
  },
  {
    name: 'Easter Monday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-10-2023',
    date_year: '2023',
    date_month: '04',
    date_day: '10',
    week_day: 'Monday',
  },
  {
    name: 'Labor Day - May Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-01-2023',
    date_year: '2023',
    date_month: '05',
    date_day: '01',
    week_day: 'Monday',
  },
  {
    name: 'Day of the Flag',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-02-2023',
    date_year: '2023',
    date_month: '05',
    date_day: '02',
    week_day: 'Tuesday',
  },
  {
    name: 'Constitution Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-03-2023',
    date_year: '2023',
    date_month: '05',
    date_day: '03',
    week_day: 'Wednesday',
  },
  {
    name: "Mother's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-26-2023',
    date_year: '2023',
    date_month: '05',
    date_day: '26',
    week_day: 'Friday',
  },
  {
    name: 'Whit Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-28-2023',
    date_year: '2023',
    date_month: '05',
    date_day: '28',
    week_day: 'Sunday',
  },
  {
    name: 'Corpus Christi',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-08-2023',
    date_year: '2023',
    date_month: '06',
    date_day: '08',
    week_day: 'Thursday',
  },
  {
    name: 'June Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '06-21-2023',
    date_year: '2023',
    date_month: '06',
    date_day: '21',
    week_day: 'Wednesday',
  },
  {
    name: "Father's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '06-23-2023',
    date_year: '2023',
    date_month: '06',
    date_day: '23',
    week_day: 'Friday',
  },
  {
    name: 'Assumption of Mary',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '08-15-2023',
    date_year: '2023',
    date_month: '08',
    date_day: '15',
    week_day: 'Tuesday',
  },
  {
    name: 'September Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '09-23-2023',
    date_year: '2023',
    date_month: '09',
    date_day: '23',
    week_day: 'Saturday',
  },
  {
    name: "All Saints' Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-01-2023',
    date_year: '2023',
    date_month: '11',
    date_day: '01',
    week_day: 'Wednesday',
  },
  {
    name: 'Independence Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-11-2023',
    date_year: '2023',
    date_month: '11',
    date_day: '11',
    week_day: 'Saturday',
  },
  {
    name: 'December Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '12-22-2023',
    date_year: '2023',
    date_month: '12',
    date_day: '22',
    week_day: 'Friday',
  },
  {
    name: 'Christmas Eve',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-24-2023',
    date_year: '2023',
    date_month: '12',
    date_day: '24',
    week_day: 'Sunday',
  },
  {
    name: 'Christmas Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-25-2023',
    date_year: '2023',
    date_month: '12',
    date_day: '25',
    week_day: 'Monday',
  },
  {
    name: 'Second Day of Christmas',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-26-2023',
    date_year: '2023',
    date_month: '12',
    date_day: '26',
    week_day: 'Tuesday',
  },
  {
    name: "New Year's Eve",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-31-2023',
    date_year: '2023',
    date_month: '12',
    date_day: '31',
    week_day: 'Sunday',
  },
  {
    name: "New Year's Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-01-2024',
    date_year: '2024',
    date_month: '01',
    date_day: '01',
    week_day: 'Monday',
  },
  {
    name: 'Epiphany',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-06-2024',
    date_year: '2024',
    date_month: '01',
    date_day: '06',
    week_day: 'Saturday',
  },
  {
    name: "Valentine's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '02-14-2024',
    date_year: '2024',
    date_month: '02',
    date_day: '14',
    week_day: 'Wednesday',
  },
  {
    name: 'March Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '03-20-2024',
    date_year: '2024',
    date_month: '03',
    date_day: '20',
    week_day: 'Wednesday',
  },
  {
    name: 'Good Friday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '03-29-2024',
    date_year: '2024',
    date_month: '03',
    date_day: '29',
    week_day: 'Friday',
  },
  {
    name: 'Holy Saturday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '03-30-2024',
    date_year: '2024',
    date_month: '03',
    date_day: '30',
    week_day: 'Saturday',
  },
  {
    name: 'Easter Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '03-31-2024',
    date_year: '2024',
    date_month: '03',
    date_day: '31',
    week_day: 'Sunday',
  },
  {
    name: 'Easter Monday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-01-2024',
    date_year: '2024',
    date_month: '04',
    date_day: '01',
    week_day: 'Monday',
  },
  {
    name: 'Labor Day - May Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-01-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '01',
    week_day: 'Wednesday',
  },
  {
    name: 'Day of the Flag',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-02-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '02',
    week_day: 'Thursday',
  },
  {
    name: 'Constitution Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-03-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '03',
    week_day: 'Friday',
  },
  {
    name: 'Whit Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-19-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '19',
    week_day: 'Sunday',
  },
  {
    name: "Mother's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-26-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '26',
    week_day: 'Sunday',
  },
  {
    name: 'Corpus Christi',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-30-2024',
    date_year: '2024',
    date_month: '05',
    date_day: '30',
    week_day: 'Thursday',
  },
  {
    name: 'June Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '06-20-2024',
    date_year: '2024',
    date_month: '06',
    date_day: '20',
    week_day: 'Thursday',
  },
  {
    name: "Father's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '06-23-2024',
    date_year: '2024',
    date_month: '06',
    date_day: '23',
    week_day: 'Sunday',
  },
  {
    name: 'Assumption of Mary',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '08-15-2024',
    date_year: '2024',
    date_month: '08',
    date_day: '15',
    week_day: 'Thursday',
  },
  {
    name: 'September Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '09-22-2024',
    date_year: '2024',
    date_month: '09',
    date_day: '22',
    week_day: 'Sunday',
  },
  {
    name: "All Saints' Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-01-2024',
    date_year: '2024',
    date_month: '11',
    date_day: '01',
    week_day: 'Friday',
  },
  {
    name: 'Independence Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-11-2024',
    date_year: '2024',
    date_month: '11',
    date_day: '11',
    week_day: 'Monday',
  },
  {
    name: 'December Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '12-21-2024',
    date_year: '2024',
    date_month: '12',
    date_day: '21',
    week_day: 'Saturday',
  },
  {
    name: 'Christmas Eve',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-24-2024',
    date_year: '2024',
    date_month: '12',
    date_day: '24',
    week_day: 'Tuesday',
  },
  {
    name: 'Christmas Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-25-2024',
    date_year: '2024',
    date_month: '12',
    date_day: '25',
    week_day: 'Wednesday',
  },
  {
    name: 'Second Day of Christmas',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-26-2024',
    date_year: '2024',
    date_month: '12',
    date_day: '26',
    week_day: 'Thursday',
  },
  {
    name: "New Year's Eve",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-31-2024',
    date_year: '2024',
    date_month: '12',
    date_day: '31',
    week_day: 'Tuesday',
  },
  {
    name: "New Year's Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-01-2025',
    date_year: '2025',
    date_month: '01',
    date_day: '01',
    week_day: 'Wednesday',
  },
  {
    name: 'Epiphany',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '01-06-2025',
    date_year: '2025',
    date_month: '01',
    date_day: '06',
    week_day: 'Monday',
  },
  {
    name: "Valentine's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '02-14-2025',
    date_year: '2025',
    date_month: '02',
    date_day: '14',
    week_day: 'Friday',
  },
  {
    name: 'March Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '03-20-2025',
    date_year: '2025',
    date_month: '03',
    date_day: '20',
    week_day: 'Thursday',
  },
  {
    name: 'Good Friday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-18-2025',
    date_year: '2025',
    date_month: '04',
    date_day: '18',
    week_day: 'Friday',
  },
  {
    name: 'Holy Saturday',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '04-19-2025',
    date_year: '2025',
    date_month: '04',
    date_day: '19',
    week_day: 'Saturday',
  },
  {
    name: 'Easter Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-20-2025',
    date_year: '2025',
    date_month: '04',
    date_day: '20',
    week_day: 'Sunday',
  },
  {
    name: 'Easter Monday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '04-21-2025',
    date_year: '2025',
    date_month: '04',
    date_day: '21',
    week_day: 'Monday',
  },
  {
    name: 'Labor Day - May Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-01-2025',
    date_year: '2025',
    date_month: '05',
    date_day: '01',
    week_day: 'Thursday',
  },
  {
    name: 'Day of the Flag',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-02-2025',
    date_year: '2025',
    date_month: '05',
    date_day: '02',
    week_day: 'Friday',
  },
  {
    name: 'Constitution Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '05-03-2025',
    date_year: '2025',
    date_month: '05',
    date_day: '03',
    week_day: 'Saturday',
  },
  {
    name: "Mother's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '05-26-2025',
    date_year: '2025',
    date_month: '05',
    date_day: '26',
    week_day: 'Monday',
  },
  {
    name: 'Whit Sunday',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-08-2025',
    date_year: '2025',
    date_month: '06',
    date_day: '08',
    week_day: 'Sunday',
  },
  {
    name: 'Corpus Christi',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '06-19-2025',
    date_year: '2025',
    date_month: '06',
    date_day: '19',
    week_day: 'Thursday',
  },
  {
    name: 'June Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '06-21-2025',
    date_year: '2025',
    date_month: '06',
    date_day: '21',
    week_day: 'Saturday',
  },
  {
    name: "Father's Day",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '06-23-2025',
    date_year: '2025',
    date_month: '06',
    date_day: '23',
    week_day: 'Monday',
  },
  {
    name: 'Assumption of Mary',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '08-15-2025',
    date_year: '2025',
    date_month: '08',
    date_day: '15',
    week_day: 'Friday',
  },
  {
    name: 'September Equinox',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '09-22-2025',
    date_year: '2025',
    date_month: '09',
    date_day: '22',
    week_day: 'Monday',
  },
  {
    name: "All Saints' Day",
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-01-2025',
    date_year: '2025',
    date_month: '11',
    date_day: '01',
    week_day: 'Saturday',
  },
  {
    name: 'Independence Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '11-11-2025',
    date_year: '2025',
    date_month: '11',
    date_day: '11',
    week_day: 'Tuesday',
  },
  {
    name: 'December Solstice',
    country: 'PL',
    location: 'Poland',
    type: 'Seasonal',
    date: '12-21-2025',
    date_year: '2025',
    date_month: '12',
    date_day: '21',
    week_day: 'Sunday',
  },
  {
    name: 'Christmas Eve',
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-24-2025',
    date_year: '2025',
    date_month: '12',
    date_day: '24',
    week_day: 'Wednesday',
  },
  {
    name: 'Christmas Day',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-25-2025',
    date_year: '2025',
    date_month: '12',
    date_day: '25',
    week_day: 'Thursday',
  },
  {
    name: 'Second Day of Christmas',
    country: 'PL',
    location: 'Poland',
    type: 'National',
    date: '12-26-2025',
    date_year: '2025',
    date_month: '12',
    date_day: '26',
    week_day: 'Friday',
  },
  {
    name: "New Year's Eve",
    country: 'PL',
    location: 'Poland',
    type: 'Observance',
    date: '12-31-2025',
    date_year: '2025',
    date_month: '12',
    date_day: '31',
    week_day: 'Wednesday',
  },
];
