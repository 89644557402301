import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/delivery`;

axios.defaults.withCredentials = true;

class DeliveryService {
  static async getDeliveryPrice(items) {
    const res = await axios.post(`${url}`, { items });
    return res.data;
  }
}

export default DeliveryService;
