import defaultLang from '../static/defaultLang';

export const productNamesInOrderMixin = {
  methods: {
    getNameByCurrentLanguage(item) {
      const currentLang = this.$i18n.locale;

      if (currentLang === defaultLang.PL) {
        return item?.product?.name;
      }

      const nameInDifferentLanguage = item?.product?.langs.find(
        (lang) => lang.code === currentLang
      ).name;

      return nameInDifferentLanguage ? nameInDifferentLanguage : item?.product?.name;
    },
    mapProductNames(items) {
      return items.map((item) => {
        return {
          ...item,
          nameByLang: this.getNameByCurrentLanguage(item),
        };
      });
    },
  },
};
