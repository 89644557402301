<template>
  <v-container fluid>
    <v-radio-group v-model="value" :disabled="disableRadios">
      <template v-slot:label>
        <div class="mb-4">
          <h2>{{ $t('components.deliveryOption.title') }}</h2>
        </div>
      </template>
      <div>
        <v-radio :value="deliveryTypes.TO_CUSTOMER_DELIVERY.type">
          <template v-slot:label>
            <div>
              {{ $t('statics.deliveryTypes.TO_CUSTOMER_DELIVERY') }}.
              {{ $t('components.deliveryOption.price') }}
              <v-progress-circular v-if="loading" progress-circular indeterminate color="primary">
              </v-progress-circular>
              <strong v-else>{{ formatCurrency(deliveryPrice) }}</strong
              >.
            </div>
          </template>
        </v-radio>
        <v-radio :value="deliveryTypes.PICKUP_IN_PERSON.type">
          <template v-slot:label>
            <div>{{ $t('statics.deliveryTypes.PICKUP_IN_PERSON') }}.</div>
          </template>
        </v-radio>
      </div>
    </v-radio-group>
  </v-container>
</template>

<script>
import { formatCurrency } from '../mixins/formatCurrency';
import { deliveryTypes } from '../static/deliveryTypes';
import { countryCodeComputed } from '../store/helper';
import { countriesCodeHandler } from '../mixins/countriesCodeHandler';

export default {
  name: 'DeliveryOptions',
  mixins: [formatCurrency, countriesCodeHandler],
  props: {
    deliveryPrice: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    radios: {
      type: String,
      default: deliveryTypes().TO_CUSTOMER_DELIVERY.type,
    },
    disableRadios: {
      type: Boolean,
      dafault: false,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    refreshSelectedRadios() {
      this.value = this.radios;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.$emit('delivery-option-changed', this.value);
      },
      deep: true,
    },
  },
  computed: {
    deliveryTypes() {
      return deliveryTypes();
    },
    ...countryCodeComputed,
  },
  created() {
    this.refreshSelectedRadios();
  },
};
</script>

<style lang="scss" scoped></style>
