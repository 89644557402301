import { cartComputed, discountsComputed } from '../store/helper';
import discountTypes from '../static/discountTypes';
import { isEmpty } from 'lodash';

export const basketDiscountMixin = {
  data() {
    return {};
  },
  computed: {
    ...cartComputed,
    ...discountsComputed,
    productsWithDiscounts() {
      return !isEmpty(this.discounts);
    },
    salesmanDiscount() {
      return this.discounts.find((discount) => discount.name === discountTypes.SALESMAN_DISCOUNT)
        ?.value;
    },
    showDiscountInPercentage() {
      return `${this.salesmanDiscount * 100} %`;
    },
  },
  methods: {
    async checkForDiscounts() {
      this.$store.dispatch('checkForDiscounts', { cart: this.cart });
    },
    getPriceWithDiscount(price, product) {
      return this.discounts.length > 0 ? product.priceAfterDiscount : price;
    },
  },
};
