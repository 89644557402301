import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/orders`;

axios.defaults.withCredentials = true;

class OrderService {
  static async getAll(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllUserOrders(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/user`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getAllCompanyOrders(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/company`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getUserOrder(id) {
    const res = await axios.get(`${url}/user/${id}`);
    return res.data;
  }

  static async getCompanyOrder(id) {
    const res = await axios.get(`${url}/company/${id}`);
    return res.data;
  }

  static async getSysadminOrder(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }

  static async updateStatus(id, statusId) {
    const res = await axios.put(`${url}/${id}/status/${statusId}`);
    return res.data;
  }

  static async createOrder(order) {
    const res = await axios.post(`${url}`, order);
    return res.data;
  }

  static async updateOrder(id, updatedOrder) {
    const res = await axios.put(`${url}/${id}`, updatedOrder);
    return res.data;
  }

  static async createOrderByAdmin(order) {
    const res = await axios.post(`${url}/adminOrder`, order);
    return res.data;
  }

  static async createOrderBySalesman(order) {
    const res = await axios.post(`${url}/salesmanOrder`, order);
    return res.data;
  }

  static async getBaselinkerExtraFiledOne(baselinkerId) {
    const res = await axios.get(`${url}/fieldOne/${baselinkerId}`);
    return res.data.data;
  }

  static async generateBlob(res) {
    return new Blob([res], {
      type: 'application/pdf',
    });
  }

  static async generateOfferPdf(orderId) {
    const res = await axios.get(`${url}/${orderId}/generatePdf`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
    });
    const blob = this.generateBlob(res.data);
    return blob;
  }

  static async checkBlockade(items) {
    const res = await axios.post(`${url}/check-is-order-blocked`, {
      items,
    });
    return res.data;
  }

  static async syncOrderData(orderId) {
    const res = await axios.get(`${url}/sync-order-data/${orderId}`);

    return res.data;
  }
}

export default OrderService;
