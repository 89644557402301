import SettingsService from '../services/SettingsService';
import holidays from '../static/holidays';

export const holidayMixin = {
  data() {
    return {
      formatHolidays: [],
      holidays: holidays.holidays,
      deliveryDays: 2,
    };
  },
  methods: {
    allowedDates(val) {
      const valDate = new Date(val);

      if (valDate?.getDay() == 6 || valDate?.getDay() == 0) {
        return false;
      }

      return this.formatHolidays.indexOf(val) === -1;
    },
    nationalHolidays() {
      const nationalHolidays = this.holidays.filter((holiday) => {
        return holiday.type === 'National';
      });
      const properFormat = nationalHolidays.map((holiday) => {
        return `${holiday.date_year}-${holiday.date_month}-${holiday.date_day}`;
      });
      return properFormat;
    },
    getMinimumDeliveryDate() {
      let addedWorkDays = new Date();
      addedWorkDays = this.addWorkDays(addedWorkDays, this.deliveryDays + 1);
      let day = addedWorkDays.getDate();
      let month = addedWorkDays.getMonth() + 1;
      if (day < 10) {
        day = '0' + day;
      }

      if (month < 10) {
        month = '0' + month;
      }

      let date = addedWorkDays.getFullYear() + '-' + month + '-' + day;
      return date;
    },
    addWorkDays(startDate, days) {
      if (isNaN(days)) {
        return;
      }
      if (!(startDate instanceof Date)) {
        return;
      }
      let dow = startDate.getDay();
      let daysToAdd = parseInt(days);
      if (dow == 0) daysToAdd++;
      if (dow + daysToAdd >= 6) {
        let remainingWorkDays = daysToAdd - (5 - dow);
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
          daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
          if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
        }
      }
      startDate.setDate(startDate.getDate() + daysToAdd);
      return startDate;
    },
    async getDeliveryDays() {
      try {
        const response = await SettingsService.getDeliveryDays();
        this.deliveryDays = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.formatHolidays = this.nationalHolidays();
    await this.getDeliveryDays();
  },
};
