import VueI18n from '../plugins/i18n';

export const deliveryTypes = () => {
  return {
    TO_CUSTOMER_DELIVERY: {
      name: 'Dostawa kurierem',
      type: 'TO_CUSTOMER_DELIVERY',
      text: VueI18n.t('statics.deliveryTypes.TO_CUSTOMER_DELIVERY'),
      price: 0,
    },
    PICKUP_IN_PERSON: {
      name: 'Odbiór osobisty',
      type: 'PICKUP_IN_PERSON',
      text: VueI18n.t('statics.deliveryTypes.PICKUP_IN_PERSON'),
      price: 0,
    },
    FOREIGN_DELIVERY: {
      name: 'Dostawa za granicę',
      type: 'FOREIGN_DELIVERY',
      text: VueI18n.t('statics.deliveryTypes.FOREIGN_DELIVERY'),
      price: 0,
    },
  };
};
