<template>
  <v-col class="text-right">
    <v-row>
      <v-col>
        {{
          totalPrice
            ? formatCurrency(totalPrice, exchangeRate, shouldCalculateByCurrency)
            : 'Błąd w obliczeniach'
        }}</v-col
      ></v-row
    >
    <v-row>
      <v-col
        >{{ $t('components.priceDetails.tax') }}:
        {{
          VAT ? formatCurrency(VAT, exchangeRate, shouldCalculateByCurrency) : 'Błąd w obliczeniach'
        }}</v-col
      ></v-row
    >
    <v-row>
      <v-col
        >{{ $t('components.priceDetails.grossTotal') }}:
        {{
          totalPriceGross
            ? formatCurrency(totalPriceGross, exchangeRate, shouldCalculateByCurrency)
            : 'Błąd w obliczeniach'
        }}</v-col
      ></v-row
    >
    <v-row v-if="productsWithDiscounts">
      <v-col class="text-right"
        >{{ $t('components.priceDetails.discoutValue') }}: {{ showDiscountInPercentage }}</v-col
      ></v-row
    >
    <v-row v-if="isSysAdmin">
      <v-col
        >{{ $t('components.priceDetails.exchangeRate') }}:
        {{
          exchangeRate && exchangeRate.value
            ? formatCurrency(exchangeRate.value, exchangeRate, shouldCalculateByCurrency)
            : 'Błąd w obliczeniach'
        }}</v-col
      ></v-row
    >
  </v-col>
</template>
<script>
import { basketDiscountMixin } from '../mixins/basketDiscountMixin';
import { formatCurrency } from '../mixins/formatCurrency';
import { permissionRolesMixin } from '../mixins/permissionRolesMixin';
import { isEmpty } from 'lodash';
import discountTypes from '../static/discountTypes';

export default {
  name: 'PriceDetails',
  mixins: [formatCurrency, permissionRolesMixin, basketDiscountMixin],
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
    VAT: {
      type: Number,
      required: true,
    },
    totalPriceGross: {
      type: Number,
      required: true,
    },
    exchangeRate: {
      type: Object,
      required: true,
    },
    shouldCalculateByCurrency: {
      type: Boolean,
      default: true,
    },
    orderDiscounts: {
      type: Array,
    },
  },
  methods: {
    showDiscountIfUsed() {
      return !isEmpty(this.orderDiscounts);
    },
    salesmanDiscountInPercentage() {
      const salesmanDiscount = this.orderDiscounts.find(
        (discount) => discount.name === discountTypes.SALESMAN_DISCOUNT
      )?.value;
      return `${salesmanDiscount * 100} %`;
    },
    formatOutputValue(value) {
      return `${this.formatCurrency(value, false)} ${this.exchangeRate.name}`;
    },
  },
};
</script>
<style lang="scss" scoped></style>
